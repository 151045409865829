import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from './store';

@Module({ name: 'navigation', store: store, dynamic: true })
export default class NavigationStore extends VuexModule {
    _navigationOpened = false;
    _globalNavOpened = false;
    _searchOpened = false;
    _infoBannerOpened = false;
    _adaptiveLanguageSwitcherOpened = false;
    _activatedTab = '';

    @Mutation
    setNavigationOpened(opened: boolean) {
        this._navigationOpened = opened;
    }

    @Mutation
    setGlobalNavOpened(opened: boolean) {
        this._globalNavOpened = opened;
    }

    @Mutation
    setSearchOpened(opened: boolean) {
        this._searchOpened = opened;
    }

    @Mutation
    setActivatedTab(tab: string) {
        this._activatedTab = tab;
    }

    @Mutation
    setInfoBannerOpened(opened: boolean) {
        this._infoBannerOpened = opened;
    }

    @Mutation
    setAdaptiveLanguageSwitcherOpened(opened: boolean) {
        this._adaptiveLanguageSwitcherOpened = opened;
    }

    get navigationOpened(): boolean {
        return this._navigationOpened;
    }

    get globalNavOpened(): boolean {
        return this._globalNavOpened;
    }

    get searchOpened(): boolean {
        return this._searchOpened;
    }

    get activatedTab(): string {
        return this._activatedTab;
    }

    get bodyClasses(): number {
        if (this._navigationOpened) {
            document.body.classList.add('overflow-hidden', 'md:overflow-auto');
            return 1;
        } else if (this._globalNavOpened || this._searchOpened) {
            document.body.classList.add('overflow-hidden');
            document.body.classList.remove('md:overflow-auto');
            return 2;
        } else {
            document.body.classList.remove('overflow-hidden', 'md:overflow-auto');
            return 3;
        }
    }

    get additionalTopMarginContent() {
        return ({ prefix, sm, md, xl }: { prefix: string; sm: number; md: number; xl: number }): string => {
            if (this._adaptiveLanguageSwitcherOpened) {
                sm += 14;
                md += 14;
            }
            if (this._infoBannerOpened) {
                sm += 38;
                md += 35;
                xl += 35;
            }
            return `${prefix}-${sm} md:${prefix}-${md} xl:${prefix}-${xl}`;
        };
    }

    get additionalTopMarginHeader() {
        return ({ prefix, sm, md, xl }: { prefix: string; sm: number; md: number; xl: number }): string => {
            if (this._infoBannerOpened) {
                sm += 38;
                md += 35;
                xl += 35;
            }
            return `${prefix}-${sm} md:${prefix}-${md} xl:${prefix}-${xl}`;
        };
    }
}
