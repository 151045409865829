











import Vue from 'vue';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import Icon from './Icon.vue';
import Cookies from 'js-cookie';
import NavigationStore from '../../store/NavigationStore';
import { getModule } from 'vuex-module-decorators';

@Component({
    components: {
        Icon
    }
})
export default class InfoBanner extends Vue {
    @Prop({ default: false, type: Boolean }) value: boolean;
    @Prop({ default: false, type: Boolean }) showOnLoad: boolean;
    @Prop({ default: false, type: Boolean }) showAlways: boolean;
    @Prop({ default: false, type: Boolean }) editMode: boolean;
    @Prop({ default: 500 }) delay: number;

    navStore: NavigationStore = getModule(NavigationStore);

    visible = false;

    mounted(): void {
        const alreadyShown = !!Cookies.get('gc-banner-shown');
        if (this.editMode) {
            this.visible = true;
        } else if (this.showAlways || (this.showOnLoad && !alreadyShown)) {
            setTimeout(() => this.show(), this.delay);
        }
    }

    @Emit('input')
    protected hide(): boolean {
        if (!this.editMode && !this.showAlways) {
            this.visible = false;
            this.navStore.setInfoBannerOpened(false);
            Cookies.set('gc-banner-shown', 'true');
        }
        return false;
    }

    @Emit('input')
    protected show(): boolean {
        if (!this.editMode) {
            this.visible = true;
            this.navStore.setInfoBannerOpened(true);
        }
        return true;
    }

    @Watch('value')
    watchValue(newVal: boolean): void {
        if (newVal) {
            this.show();
        } else {
            this.hide();
        }
    }
}
