/* eslint-disable dot-notation, @typescript-eslint/camelcase */
import Vue from 'vue';
import store from './store/store';
import './component-loader';
import { VueMasonryPlugin } from 'vue-masonry';
import CountUp from './directives/count-up';
import ScrollDown from './directives/scroll-down';
import VueScrollReveal from 'vue-scroll-reveal';
import VueGtm from '@gtm-support/vue2-gtm';
import VuePositionSticky from 'vue-position-sticky';
import { extend, localize } from 'vee-validate';
import {
    confirmed,
    email,
    integer,
    max,
    max_value,
    mimes,
    min,
    min_value,
    numeric,
    regex,
    required,
    size
} from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import de from 'vee-validate/dist/locale/de.json';
import fr from 'vee-validate/dist/locale/fr.json';
import es from 'vee-validate/dist/locale/es.json';
import pt from 'vee-validate/dist/locale/pt_PT.json';
import ru from 'vee-validate/dist/locale/ru.json';
import no from 'vee-validate/dist/locale/nb_NO.json';
import * as GmapVue from 'gmap-vue';
import '../node_modules/focus-visible/dist/focus-visible.min.js';
import vueSocialSharing from 'vue-social-sharing';
import Vue2TouchEvents from 'vue2-touch-events';
import VueFlatPickr from 'vue-flatpickr-component';
import VueCompositionApi from '@vue/composition-api';
import TabActivator from './utils/TabActivator';

/* Plugins */
Vue.use(Vue2TouchEvents);
Vue.use(VueMasonryPlugin);
Vue.use(VuePositionSticky);
Vue.use(VueFlatPickr);
Vue.use(VueCompositionApi);

// google maps (get key from data attributes)
const gMapsAPIKey = document.body.dataset.gmapsApiKey;
Vue.use(GmapVue, {
    load: {
        key: gMapsAPIKey || 'AIzaSyCSrasp7xoSYNhdBANpj51-QEtNjlH0MyY'
    }
});

// for options,  see https://scrollrevealjs.org/api/reveal.html
Vue.use(VueScrollReveal, {
    distance: '3rem',
    duration: 800,
    easing: 'ease-out',
    viewFactor: 0.2
});

Vue.use(vueSocialSharing);

// tag manager integration
if (document.body.dataset.gtmId) {
    const gtmOptions: Record<string, unknown> = {
        id: document.body.dataset.gtmId,
        defer: false,
        loadScript: true,
        parentElement: document.head
    };
    if (document.body.dataset.gtmPreview) {
        gtmOptions.queryParams = {
            gtm_auth: document.body.dataset.gtmAuth,
            gtm_preview: document.body.dataset.gtmPreview,
            gtm_cookies_win: 'x'
        };
    }
    Vue.use(VueGtm, gtmOptions);
}

// load validator
localize({
    en,
    de,
    fr,
    es,
    pt,
    ru,
    no
});
localize(document.documentElement.lang);
extend('required', required);
extend('email', email);
extend('max', max);
extend('min', min);
extend('numeric', numeric);
extend('regex', regex);
extend('mimes', mimes);
extend('integer', integer);
extend('min_value', min_value);
extend('max_value', max_value);
extend('confirmed', confirmed);
extend('size', size);
extend('is_number', value => {
    if (!isNaN(value)) return true;
    return '{_field_} must be a number';
});

Vue.directive('count-up', CountUp);
Vue.directive('scroll-down', ScrollDown);

// define context- and resource path globally for vue components
Vue.prototype.$contextPath = window['contextPath'];
Vue.prototype.$resourcePath = window['resourcePath'];
Vue.prototype.$site = window['siteName'];
Vue.prototype.$lang = window['lang'];
Vue.prototype.$prototype = window['prototype'];

// filter for date formatting
const dateFormat = document.body.dataset.dateFormat || 'dd/MM/yyyy';
const formatDate = (date: string | number) => {
    if (!date) return '';
    const tdd = x => x < 10 ? `0${x}` : `${x}`;
    const d = new Date(date);
    const day = tdd(d.getDate());
    const month = tdd(d.getMonth() + 1);
    const year = '' + d.getFullYear();
    return dateFormat.replace('dd', day).replace('MM', month).replace('yyyy', year);
};
const formatTime = (date: string | number) => {
    if (!date) return 'n/a';

    const d = new Date(date);
    return d.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: true });
};

Vue.filter('formatDate', formatDate);
Vue.filter('formatTime', formatTime);
Vue.filter('formatDateTime', (date: string | number) => {
    if (!date) return 'n/a';

    const formattedDate = formatDate(date);
    const formattedTime = formatTime(date);
    return formattedDate + ' ' + formattedTime;
});

const app = new Vue({
    el: '#app',
    store,
    comments: true
});

// linking from tab to tab
/* eslint-disable no-new */
new TabActivator(app);

// declare types for global variables or prod build will fail
declare module 'vue/types/vue' {
    interface Vue {
        $contextPath: string;
        $resourcePath: string;
        $site: string;
        $lang: string;
        $prototype: boolean;
        _uid: number;
        $redrawVueMasonry: (...args: any) => any; // somehow not recognized
    }
}
